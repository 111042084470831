import '../stylesheets/application.scss'

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import tracker from './application/tracker'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// 因為之前的 Backtail 前端使用 Nuxt 建構，註冊的 ServiceWorker 會一直發 requests 戳後段
if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
}
