document.querySelector('body').addEventListener('click', (event) => {
  const isDataTrack = event.target.hasAttribute('data-track')

  if (!isDataTrack) {
    return
  }

  const params = JSON.parse(event.target.dataset.track)

  const category = params[0]
  const action = params[1]
  const label = params[2]

  window.gtag && window.gtag('event', action, {
    event_category: category,
    event_label: label
  })
})
